<template>
  <v-container class="ma-0 pa-0">
    <v-row dense class="mt-2">
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        vertical
        color = "success">
        {{text}}

        <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>

     </v-snackbar>

    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-2">

        <v-card  class="elevation-5 ma-1 px-1" >
          <v-card-title >
            <div class="blue--text" >Fecha: {{newFecha}}</div>
            <v-spacer></v-spacer>

            <div class="red--text" >Cotización: {{docant.Numdoc}}</div>
          <v-spacer></v-spacer>
            <v-btn color="info" small @click="regresar"><v-icon>reply</v-icon></v-btn>
          </v-card-title>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1"  >

          <v-card-title
            class="ma-0 py-0">Datos del Pago en     {{moneda}}
          </v-card-title>

          <v-card-text>
            <table width="100%">
              <tr>
                <th style="text-align:left;">IMPORTE: </th>
                <th style="text-align:right;">{{ getTotales.importe}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">DESCUENTO: </th>
                <th style="text-align:right;">{{ getTotales.descuento}}</th>
              </tr>

              <tr>
                <th style="text-align:left;">SUBTOTAL: </th>
                <th style="text-align:right;">{{ getTotales.subtotal}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">IVA: </th>
                <th style="text-align:right;">{{ getTotales.iva}}</th>
              </tr>
              <tr>
                <th style="text-align:left;">TOTAL: </th>
                <th style="text-align:right;">{{ getTotales.total}}</th>
              </tr>
            </table>
          </v-card-text>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1">
          <v-card-title
            class="ma-1 py-1">Datos del Cliente
          </v-card-title>

          <v-card-text >
              <div><strong>{{ Clientes.nomcli }}</strong></div>
              <div><strong>{{ Clientes.Rfc }}</strong></div>
              <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
              <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
              <div>Atención :{{ Clientes.atvent}}  </div>
                  <div> Email :{{Clientes.email1}} </div>
              <div>{{ Clientes.telefono}}</div>
          </v-card-text>
        </v-card>

        <v-card class="elevation-2 ma-1 px-1" >

          <v-card-title
            v-if="Recogeentienda"
            class="ma-0 py-0">Recoge en Tienda
          </v-card-title>

          <v-card-title
            v-else class="ma-0 py-0">Dirección de Envío
          </v-card-title>

          <v-card-text
            class="pa-0 ma-0"
            v-if="mismaDireccion">
              <div>Atención: {{ Clientes.atvent}}   </div>
              <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
              <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
              <div>{{ Clientes.telefono}}</div>
          </v-card-text>

          <v-card-text class="ma-0 py-0" v-else>
              <div>{{ Direnvio}}</div>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="12" md="6" sm="6" xs="12" >
        <v-card :loading="loading" elevation= "10" class="ma-1 pa-1">

          <v-card-title
            class="ma-1 py-1">Pago con tarjeta por
              <span class="mx-2 red--text text-right" >  {{ getTotales.total }}  {{moneda}} </span>
          </v-card-title>

          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-select
                class="mx-4"
                v-model="msi"
                :items="msiArray"
                label="Meses Sin Intereses"
                outlined
                hide-details
                dense
                readonly
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-img
                src="@/assets/openpay/cards1.png"
                aspect-ratio="1.7"
                contain
                height="40"
              ></v-img>
            </v-col>
          </v-row >

            <v-form

              ref="form5"
              v-model="valid"
              lazy-validation >
              <v-container  grid-list-md>

                <v-text-field
                  prepend-icon="person"
                  label="Nombre del titular"
                  v-model="name_titular"
                  type="text"
                  required
                  :rules="[v => !!v || 'El nombre del titular es requerido']"
                  autocomplete="off"

                ></v-text-field>

                <v-text-field
                  prepend-icon="person"
                  label="Apellido del titular"
                  v-model="last_name_titular"
                  type="text"
                  required
                  :rules="[v => !!v || 'El nombre del titular es requerido']"
                  autocomplete="off"

                ></v-text-field>

               <v-text-field
                  prepend-icon="payment"
                  name="card"
                  label="Número de tarjeta"
                  type="text"
                  :mask="mask_credit_card"
                  v-model="number_card"
                  required

                  :rules="[v => !!v || 'El número de tarjeta es requerido']"
                  autocomplete="off"
                ></v-text-field>

                <!-- <v-text-field
                  prepend-icon="mail"
                  name="email"
                  label="Correo electrónico"
                  type="email"
                  v-model="email"
                  required
                  autocomplete="off"
                  :rules="emailRules"
                ></v-text-field> -->

                <v-row>
                  <v-col cols="5" class="ma-1 pa-1">
                    <v-select
                      prepend-icon="fa-calendar-o"
                      :items="items_months"
                      v-model="month_expiration"
                      label="Mes"
                      required
                      :rules="[v => !!v || 'El mes de expiración es requerido']"
                    ></v-select>
                  </v-col>

                  <v-col cols="3" class="ma-0 pa-0">
                    <v-text-field
                        prepend-icon="fa-calendar-o"
                        label="Año"
                        v-model="year_expiration"
                        :rules="rules_year"
                        type="number"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field
                        prepend-icon="fa-credit-card-alt"
                        name="cvc"
                        label="CVC"
                        type="number"
                        v-model="cvv2"
                        :rules="[rules_cvc.required, rules_cvc.min, rules_cvc.max]"
                        required
                        autocomplete="off"
                      ></v-text-field>
                  </v-col>
                </v-row>

                <v-row align="center">

                  <v-img class="ma-2"
                    src="@/assets/logofiserv.png"
                    aspect-ratio="1.7"
                    contain
                    height="80"
                  ></v-img>

                  <v-btn  :color="colorButton"
                    class="white--text text-capitalize ma-5"
                    :loading="loading"
                    @click.prevent="pagarFiserv"
                    :disabled="!valid">
                    {{ mesageButton }}
                    <v-icon>{{ iconButton }}</v-icon>
                  </v-btn>

                </v-row>

                <v-row v-if="mostrarMensaje">
                  <v-col cols="12">
                    <v-alert  type="success">
                        Estatus:
                        <span class="mx-2" >{{ estatus}}</span>
                    </v-alert>
                  </v-col>
                </v-row>

            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <!-- {{ cobros }} -->

    <v-row class="mx-4">

    <!--  <v-btn @click="enviar3ds"
      color="success">Mandar parchar
    </v-btn>

     MD  {{mD}}
     <br></br>
     PaReq {{ paReq }}
     <br></br>
     termUrl {{ termURL }}
     <br></br>
     acsurl   {{ acsURL }}
 -->
     <!-- @submit.prevent="enviar3ds" -->
      <form name="formulario1" :action="action" method="POST"  >
        <input type="hidden" name="PaReq" :value="paReq">
        <input type="hidden" name="TermUrl" :value= "termURL">
        <input type="hidden" name="MD" :value="mD" >
      </form>

     <!--  <v-btn
        @click.prevent="enviar3ds"
        color="success"> re enviar
      </v-btn> -->

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
var base64 = require('base-64')
var accounting = require('accounting')

export default {
  data: () => ({
    action: '',
    loading: false,
    mensaje: '',
    estatus: '',
    mostrarMensaje: false,
    // DATOS DEL PAGO
    totaldoc: 0.00,
    tokenSait: '',
    email1: '',
    email2: '',
    Recogeentienda: false,
    tipopago: '',
    mismaDireccion: true,
    Direnvio: '',
    docant: '',
    Clientes: '',
    credito: false,
    mensaje: '',

    direccion: '',
    totales: {},

    fecha: new Date().toISOString().substr(0, 10),
    // fecha:  new Date             comprobanteModal: false,
    saldo: 0,
    dias: 0,
    snackbar: false,
    text: '',
    paidFor: false,
    loadding: false,
    totalpagar: '',
    cart: '',

    res: '',
    moneda: '',
    invoicesNumber: '',

    valid: true,
    number_card: '',
    mask_credit_card: 'credit-card',
    rules_cvc: {
      required: v => !!v || 'El cvc es requerido',
      min: v => (v && v.length >= 3) || 'Mínimo 3 números',
      max: v => (v && v.length <= 4) || 'Máximo 4 números'
    },
    items_months: [
      { text: '01 - Enero', value: '01' },
      { text: '02 - Febrero', value: '02' },
      { text: '03 - Marzo', value: '03' },
      { text: '04 - Abril', value: '04' },
      { text: '05 - Mayo', value: '05' },
      { text: '06 - Junio', value: '06' },
      { text: '07 - Julio', value: '07' },
      { text: '08 - Agosto', value: '08' },
      { text: '09 - Septiembre', value: '09' },
      { text: '10 - Octubre', value: '10' },
      { text: '11 - Noviembre', value: '11' },
      { text: '12 - Diciembre', value: '12' }
    ],

    holder_name: '',
    name_titular: '',

    email: '',
    last_name_titular: '',
    month_expiration: '',
    year_expiration: '',
    cvv2: '',
    rules_year: [
      v => !!v || 'El año de expiración es requerido',
      v => (v && v.length >= 2) || 'Mínimo 2 números',
      v => (v && v.length <= 2) || 'Máximo 2 números'
    ],
    emailRules: [
      v => !!v || 'El correo electrónico es requerido',
      v => /.+@.+/.test(v) || 'El correo electrónico debe de ser valido'
    ],

    token: '',

    envfiserv: '',

    mesageButton: 'Pagar',
    iconButton: 'attach_money',
    colorButton: 'blue darken-4',

    messageSignature: '',
    // COBROS
    cobros: null,
    usafiserv: 0,
    idfiserv: '',

    msi: 3,
    msiArray: [1, 3, 6, 12],

    // 3DSECURE
    acsURL: '',
    paReq: '',
    mD: '',
    termURL: ''
  }),

  computed: {
    ...mapGetters('Login', ['getClientes', 'getdatosUsuario']),
    ...mapGetters('documento', ['getDocumento', 'getTotales']),

    validaInfo () {
      // Son dos payload uno segun el modo en que este la ventana
      return this.cvv2 > 0 && this.year_expiration >= 21 && this.name_titular !== '' && this.last_name_titular !== ''
    },

    newFecha (fecha) {
      return fecha ? this.$moment(fecha).format('DD-MMM-YYYY') : ''
    }

  },

  created () {
    // grabar en localhost.
    // idusuarioweb = this.state.usuarios.usu
    console.log('getdatosUsuario', this.getdatosUsuario)

    // Colocar Token en LocalStorage
    localStorage.setItem('idusuarioweb', this.getdatosUsuario.idusuariosweb)

    this.loading = true
    // PARA QUE ES ESTA API? por que no se corrige el original.

    const urlFiserv = 'auth/api/v1/getcobrosconfig'

    this.loading = true
    this.$http.get(urlFiserv).then(response => {
      console.log('getcobrosconfig', response)
      this.cobros = response.data

      // Usa
      this.usafiserv = !!parseInt(this.cobros.usafiserv)
      // ID
      this.idfiserv = this.cobros.idfiserv
      const urlcobro = 'auth/api/v1/tipocobro.list/' + this.idfiserv

      // console.log(this.cobros.envpaypal)
      this.envfiserv = this.cobros.envfiserv == 'P' ? 'Production' : 'Sandbox'
      this.msi = this.cobros.msi

      // API PARA OBTENER LAS LLAVES.
      this.$http.get('auth/api/v1/tipocobro.list/' + this.idfiserv).then(response => {

      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      this.snackbar = true; this.color = 'error'
      this.text = 'Problemas para cargar información'
      // console.log(error)
    })

    this.totalpagar = this.getTotales.total
    // console.log("totalpagar",this.totalpagar)

    this.totaldoc = parseFloat(accounting.unformat(this.totalpagar))
    // console.log("totaldoc",this.totaldoc)

    // this.totaldoc = parseFloat(this.totalpagar)
    // console.log("totaldoc",this.totaldoc)
    this.tipopago = this.$route.params.tipo
    // console.log("this.getTotales",this.getTotales)

    // this.validaCredito()
    console.log("getDocumento FALLA ",this.getDocumento)
    this.Iddocum = this.getDocumento.iddocum
    localStorage.setItem('iddocum', this.Iddocum)
    // this.tipocobro.id
    localStorage.setItem('gettipo', 3)

    // console.log("DOCUM", this.getDocumento)

    // this.tipoPago = this.$route.params
    this.Clientes = this.getClientes
    // console.log("clientes", this.getClientes)

    // PARA QUE ES ESTA API? por que no se corrige el original.
    this.$http.get('api/v1/email.list').then(response => {
      // console.log(response)
      this.email1 = response.body.Email1
      this.email2 = response.body.Email2
    }).catch(err => { console.log(err) })

    // CONSULTAR ORDER
    this.$http.get('api/v1/order.info/' + this.Iddocum).then(response => {
      this.docant = response.body
      console.log('Doumento', this.docant)

      this.invoicesNumber = this.docant.Numdoc
      // console.log(this.docant)
      if (this.docant.divisa == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      if (this.docant.numclisuc == '') {
        this.mismaDireccion = true
      } else {
        this.mismaDireccion = false
        this.Direnvio = this.docant.Direnvio
      }

      if (this.docant.Direnvio.trim() != '' && this.docant.Numclisuc != '') {
        this.Recogeentienda = false
      } else {
        this.Recogeentienda = true
      }

      this.loading = false
    }).catch(err => { console.log(err) })

    // LOGIN DE API SAITNUBE
    if (process.env.VUE_APP_SAIT_NUBE !== '') {
      var Payload = {
        numuser: process.env.VUE_APP_UN,
        password: process.env.VUE_APP_PW
      }

      // API AXIOS SAIT NUBE
      var url = process.env.VUE_APP_SAIT_NUBE
      var uri = url + '/api/v1/login'
      // var url = 'https://sait-md3.saitnube.com/api/v1/login'

      // console.log("SAIT NUBE uri", uri, Payload)

      // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.post(uri, Payload, {
        headers:
          { 'Content-Type': 'application/json' }
      }).then(response => {
        // console.log("Sait NUBE",response.data)
        this.tokenSait = response.data.token
        // console.log(this.tokenSait)
        localStorage.setItem('tokensait', this.tokenSait)
      }).catch(error => { console.log(error) })
    }
  },

  methods: {
    regresar () {
      this.$router.push({ name: 'tipopago' }).catch(error => { console.log(error) })
    },

    pagarFiserv () {
      this.mostrarMensaje = false
      // VALIDAINFO
      if (!this.validaInfo) {
        this.snackbar = true; this.color = 'warning'
        this.text = 'Falla al Capturar datos.'
        return
      }

      this.loading = true

      const urlFiserv = 'cert.api.firstdata.com/gateway/v2'
      const rutaCardInfo = urlFiserv + '/card-information'
      const uuidFiserv = this.docant.Numdoc

      // VUE_APP_OPTIONS_ROOT=https://saitmd3.com/tienda3
      const baseURL = process.env.VUE_APP_OPTIONS_ROOT
      // console.log("termURl", baseURL)

      const payload = {
        requestType: 'PaymentCardSaleTransaction',

        transactionAmount: {
          total: this.totaldoc,
          currency: this.moneda
        },
        paymentMethod: {
          paymentCard: {
            number: this.number_card,
            securityCode: this.cvv2,
            expiryDate: {
              month: this.month_expiration,
              year: this.year_expiration
            }
          }
        },
        order: {
          installmentOptions: {
            numberOfInstallments: this.msi
          }
        },
        authenticationRequest: {
          authenticationType: 'Secure3D21AuthenticationRequest',
          termURL: baseURL + '/finalizar3ds',
          methodNotificationURL: baseURL + '/version',
          challengeIndicator: '03'
        }
      }

      // "termURL": "https://saitmd3.com/tienda3/finalizar3ds",
      // "methodNotificationURL": "https://saitmd3.com/tienda3/version",

      const cPayload = JSON.stringify(payload)
      // console.log("cPayload", cPayload)

      const cPayload2 = {
        bodystring: cPayload
      }

      // console.log("cPayload2", cPayload2)
      // 5426064000424979   4931580001642617

      // grabar en localhost.
      // idusuarioweb = this.state.usuarios.usu
      // console.log("getdatosUsuario", this.getdatosUsuario)

      console.log('this.idfiserv', this.idfiserv.toString())
      const cidfiserv = this.idfiserv.toString()

      this.$http.post('auth/api/v1/payments/' + cidfiserv, cPayload2).then(response => {
        console.log('respnse payments', response)

        const pagoRes = response.data
        this.acsURL = pagoRes.authenticationResponse.params.AcsURL
        this.paReq = pagoRes.authenticationResponse.params.payerAuthenticationRequest
        this.mD = pagoRes.authenticationResponse.params.merchantData
        this.termURL = pagoRes.authenticationResponse.params.termURL
        this.action = pagoRes.authenticationResponse.params.AcsURL

        document.getElementsByName('MD').value = pagoRes.authenticationResponse.params.merchantData
        document.getElementsByName('PaReq').value = pagoRes.authenticationResponse.params.payerAuthenticationRequest
        document.getElementsByName('TermURL').value = pagoRes.authenticationResponse.params.termURL
        document.getElementsByName('formulario1').action = pagoRes.authenticationResponse.params.AcsURL
        document.getElementsByName('formulario1').method = 'POST'

        console.log(response.data.processor.responseMessage)
        if (pagoRes.processor.responseCode === '00') {
          this.mesageButton = 'Pagado'
          this.loadingPay = false
          this.iconButton = 'check_circle'
          this.colorButton = 'red'

          this.text = 'Pedido generado correctamente ' + pagoRes.transactionStatus
          this.snackbar = true
          // console.log(response)
          this.mensaje = pagoRes.responseMessage
          this.estatus = pagoRes.transactionStatus
          this.mostrarMensaje = true

          // MANDA PROCESAR EL PEDIDO
          this.saveinfo()
        } else {
          // this.text = 'Problemas al generar tu pago: ' + pagoRes.transactionStatus
          // this.snackbar = true
          // // console.log("Error", pagoRes.processor.responseMessage)
          // this.mensaje = pagoRes.processor.responseMessage
          // this.estatus = pagoRes.transactionStatus
          // this.mostrarMensaje= true
          // this.loading= false
        }
        // this.paso3()
      }).catch(error => {
        console.log('Hubo un problema con tu pago')
        console.log('error: ', error)
      }).finally(() => {
        // mandar parchar.
        this.enviar3ds()
      })
    },

    // 4931580001642617
    enviar3ds () {
      console.log('this.acsURL', this.acsURL)
      document.getElementsByName('MD').value = this.mD
      document.getElementsByName('PaReq').value = this.paReq
      document.getElementsByName('TermURL').value = this.termURL
      document.getElementsByName('formulario1').action = this.acsURL
      document.getElementsByName('formulario1').method = 'POST'

      document.formulario1.submit()
    },

    horaSait () {
      var f = new Date()
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }
      return hora + ':' + minuto + ':' + segundo
    },

    enviarCorreo (documento1) {
      // console.log("this.docant", this.docant)
      var correo = ''
      if (this.getdatosUsuario.numcli) {
        correo = this.getdatosUsuario.Email
      } else {
        correo = this.CargaCliente.email1
      }

      if (this.$store.state.tipomoneda == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      // console.log("documento1.numdoc",documento1[0].numdoc )
      // La respuesta de order.add incluye el iddocum del generado
      var iddocum = base64.encode(documento1[0].iddocum)
      // var iddocum = base64.encode(param[0].iddocum);

      var url = this.$http.options.root.replace('tienda3', 'consultacoti/')

      this.CargaCliente = this.getClientes
      // console.log(this.CargaCliente)
      // console.log("correo", correo)

      var payload = {
        email: correo,
        subject: 'Pedido Tienda en Linea ',
        info1: 'Pedido',
        folio: documento1[0].numdoc.trim(),
        info3: 'Se ha elaborado un pedido con el folio: ',
        nombre: this.getdatosUsuario.Nomuser,
        direccion: '',
        fecha: this.fecha,

        telefono: this.getdatosUsuario.Telefono,
        divisa: this.moneda,

        rfc: this.CargaCliente.Rfc,

        subtotal: this.getTotales.subtotal,
        iva: this.getTotales.iva,
        total: this.getTotales.total,

        c2a_link: url + iddocum,
        hora: this.horaSait(),
        logo: this.logo,

        color: this.$vuetify.theme.themes.light.secondary,
        email1: this.email1,
        email2: this.email2
      }

      // console.log(payload)s
      // Enviar el correo
      this.$http.post('auth/api/v1/sendcot', payload).then(response => {
        // console.log("enviando Correo")
        // var doc = {doc: iddocum}
        // this.$router.push({name:'documento', params: {info: doc}})
      }).catch(error => { console.log(error) })
    },

    saveinfo () {
      this.loading = true
      var me = this
      var documento = this.docant
      documento.status = 9

      var payload = {
        status: 9,
        Tipodoc: this.docant.Tipodoc,
        Numdoc: this.docant.Numdoc
      }
      // console.log(payload)
      // actualizar el estatus
      this.$http.put('auth/api/v1/updatestatus', payload).then(response => {
        // console.log(response)
      }).catch(error => { console.log(error) })

      /// ///////////////////////////////////
      // Agregarmos el pedido de esa cotización

      documento.Direnvio = documento.Direnvio
      documento.Tipodoc = ' P'
      documento.Fechapago = this.fecha
      documento.Fecha = this.fecha
      documento.Refer = documento.Numdoc
      documento.status = 0

      var HoraPed = this.horaSait()

      // console.log("Documento a convertir a Pedido", documento)

      this.$http.post('api/v1/order.add', documento).then(response => {
        this.text = 'Pedido generado correctamente'
        this.snackbar = true
        // console.log("order add", response.body)
        this.enviarCorreo(response.body)
        this.loading = false
        setTimeout(function () { me.$router.push({ name: 'cotizaciones' }) }, 3000)
      }).catch(error => {
        console.log(error)
      })

      // console.log("DOCUMENTO", documento)

      var items = []
      var i = 1

      documento.Movim.forEach((element) => {
        items.push({
          tipodoc: ' P',
          numdoc: 'A1',
          numpar: i,
          numart: element.Numart,
          desc: 'Manuel',
          pend: element.Cant,
          pjedesc: element.Pjedesc,
          cant: element.Cant,
          unidad: element.Unidad,
          impuesto1: element.Impuesto1,
          impuesto2: 0,
          precio: parseFloat(element.Precio),
          obs: ''
        })
        i = i + 1
      })

      // console.log(items)
      // GRabar pedido en Sait.
      // console.log(documento)
      var Pedidodoc = {
        tipodoc: documento.Tipodoc,
        numdoc: 'P55',
        numuser: '',
        nunvend: '',

        fecha: documento.Fecha,
        fentrega: documento.Fecha,
        fechacapt: documento.Fecha,

        divisa: documento.divisa,

        hora: HoraPed,
        hentrega: '',

        status: 1,
        formapago: '1',
        numalm: documento.Numalm,
        impuesto1: documento.impuesto1,
        impuesto2: 0,
        descuento: documento.descuento,
        importe: documento.Importe,
        numcli: documento.Numcli,
        pjedesc: 0,
        nomcli: '',
        direnvio: documento.Direnvio,
        tc: documento.Tc,
        obs: documento.Obs,
        items: items
      }

      // MANDAR LLAMAR PEDIDO SI TIENE API SAIT NUBE
      // API AXIOS SAIT NUBE
      if (process.env.VUE_APP_SAIT_NUBE !== '') {
        // VUE_APP_SAIT_NUBE
        // console.log("SAIT NUBE PEDIDO DOC", Pedidodoc)
        // console.log("TokenSait", this.tokenSait)
        var url = process.env.VUE_APP_SAIT_NUBE

        var uri = '/api/v1/pedidos'

        // API AXIOS SAIT NUBE
        // var url = 'https://sait-md3.saitnube.com/api/v1/pedidos'
        const urlped = url + uri
        axios.post(urlped, Pedidodoc, { headers: { 'Content-Type': 'application/json', 'x-token': this.tokenSait } }).then(response => {
          // console.log("Sait NUBE",response.data)
          this.loading = false
        }).catch(error => { console.log(error) })
      }
    }
  }
}

//   paso3 (){
//     // 3DSECURE
//       let payload3DS = {
//         "PaReq" : this.paReq,
//         "TermURL": this.termURL,
//         "MD" : this.mD
//       }

//       let cPayload3ds = JSON.stringify(payload3DS)

//       console.log("PASO 3 ",cPayload3ds)
//       // this.loading = true

//     //   // 4931580001642617
//     // this.$http.post(this.acsURL, payload3DS, { headers: {
//     //     'Access-Control-Allow-Origin': 'https://www.saitmd3.com',
//     //     'Access-Control-Allow-Origin': 'true',
//     //     'Access-Control-Allow-Methods': 'POST'
//     //   }
//     // }).then(response=>{
//     //   console.log("Historial por linea",response)

//     // }).catch( error => {
//     //   console.log(error)
//     // })

//     // this.$router.push(this.acsURL).then().catch()

// //     mode: 'cors', // no-cors, *cors, same-origin
// // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
// // credentials: 'same-origin', // include, *same-origin, omit

//   // pasar.onsubmit = async (e) => {
//   //   e.preventDefault();

//   //   let response = await fetch(this.acsURL, {
//   //     method: 'POST',
//   //     mode: 'same-origin',
//   //     credentials: 'same-origin',
//   //     body: new FormData(formElem)
//   //   });

//   //   let result = await response.json();
//   //   cosole.log("responseMessage ", result)
//   //   alert(result.message);
//   // };

//     // fetch(this.acsURL , {
//     //   method: 'POST',
//     //   Origin: 'https://www.saitmd3.com',
//     //   body: cPayload3ds
//     // }).then(response=>{
//     //   // console.log("Historial por linea",response)
//     // }).catch( error => {
//     //   console.log(error)
//     // })

//       // 4931580001642617
//       // axios.post(this.acsURL, cPayload3ds, { headers:
//       //   { 'Content-Type': 'application/x-www-form-urlencoded',
//       //       'Access-Control-Allow-Origin': 'https://www.saitmd3.com',
//       //       'Access-Control-Allow-Origin': 'true',
//       //       'Access-Control-Allow-Methods': 'POST',
//       //       'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers,',
//       //   }}).then(response =>  {
//       //     // console.log("Sait NUBE",response.data)
//       //     this.loading= false
//       //     console.log("response form", response)
//       //     console.log ("vete ya.")
//       // }).catch(error => {console.log("error cors", error) })

//   // var invocation = new XMLHttpRequest()
//   // var url = this.acsURL

//   // function callOtherDomain() {
//   //   if(invocation) {
//   //     invocation.open('POST', url, true);
//   //     invocation.onreadystatechange = handler;
//   //     invocation.send();
//   //   }
//   // }

//   },

//       SchemeTransactionId: ""
//       apiTraceId: "rrt-0997f86ff0543d16e-b-ea-6931-14048867-1"
//       approvedAmount: {total: 0, currency: ""}
//       clientRequestId: "f0af08fb-9a3e-4473-8534-a7035d73aec1"
//       ipgTransactionId: "84557297501"
//       processor: {responseCode: "12", responseMessage: "12-INV TRAN", authorizationCode: "000000"}
//       transactionStatus: "DECLINED"
//       transactionTime: 1614879910
//       transactionType: "SALE"

// SchemeTransactionId: ""
// apiTraceId: "rrt-0d1895ea9cfaf85ee-c-wo-9919-82130174-1"
// approvedAmount: {total: 0, currency: ""}
// clientRequestId: "a3a95ec1-b139-4b84-bab9-9851b3c5c3a9"
// ipgTransactionId: ""
// processor: {responseCode: "", responseMessage: "", authorizationCode: ""}
// transactionStatus: ""
// transactionType: ""

</script>
